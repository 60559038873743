import React, { ChangeEvent, FormEvent, Fragment, useEffect, useState } from 'react';
import { IGlobalDataContext, withGlobalData } from "./contexts/global-data";
import { Helmet } from "react-helmet";
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { postAPI, vendingMachineGroupServiceApi } from "./api"
import Modal from 'react-modal';
import BusyIndicator, { LoadingSpanner } from "./busy_indicator";
import formATree, { TreeItem } from "./utils/tree";
import { useHistory } from "react-router-dom";

interface Props extends IGlobalDataContext {

}

interface AddGroupStructure {
    isModalOpen: boolean
    parentUuid: string | null
    name: string
    description: string
}

interface EditGroupStructure {
    isModalOpen: boolean
    uuid: string | null
    name: string
    description: string
}

interface GroupInfo {
    organization_name: string
    organization_contact: string
    organization_email: string
    organization_intro: string
}

function VendingMachineHierarchyManagement(props: Props) {
    const [tree, setTree] = useState<TreeItem[]>([]);
    const [addGroupStructure, setAddGroupStructure] = useState<AddGroupStructure>({
        isModalOpen: false,
        parentUuid: null,
        name: "",
        description: "",
    });
    const [editGroupStructure, setEditGroupStructure] = useState<EditGroupStructure>({
        isModalOpen: false,
        uuid: null,
        name: "",
        description: "",
    });

    const [groupInfo, setGroupInfo] = useState<GroupInfo>({
        organization_name: "",
        organization_contact: "",
        organization_email: "",
        organization_intro: "",
    });

    const [isAddSaving, setIsAddSaving] = useState<boolean>(false);
    const [isEditSaving, setIsEditSaving] = useState<boolean>(false);
    const [isRemoveSaving, setIsRemoveSaving] = useState<boolean>(false);

    const closeAddGroupModal = () => {
        setAddGroupStructure({
            isModalOpen: false,
            parentUuid: null,
            name: "",
            description: "",
        });
    }

    const openAddGroupModal = (parentUuid: string) => {
        setAddGroupStructure({
            isModalOpen: true,
            parentUuid: parentUuid,
            name: "",
            description: "",
        });
    }

    const handleNewGroupNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAddGroupStructure({ ...addGroupStructure, name: e.target.value })
    }

    const handleNewGroupDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAddGroupStructure({ ...addGroupStructure, description: e.target.value })
    }

    const closeEditGroupModal = () => {
        setEditGroupStructure({
            isModalOpen: false,
            uuid: null,
            name: "",
            description: "",
        });
        resetGroupInfo();
    }
    const resetGroupInfo = () => {
        setGroupInfo({
            organization_name: "",
            organization_contact: "",
            organization_email: "",
            organization_intro: "",
        })
    }

    const openEditGroupModal = (uuid: string, name: string, description: string) => {
        setEditGroupStructure({
            isModalOpen: true,
            uuid: uuid,
            name: name,
            description: description,
        });
        getGroupInfo(uuid)
    }

    const handleEditGroupNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEditGroupStructure({ ...editGroupStructure, name: e.target.value })
    }

    const handleEditGroupDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEditGroupStructure({ ...editGroupStructure, description: e.target.value })
    }

    const handleOrganizationNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setGroupInfo({ ...groupInfo, organization_name: e.target.value })
    }
    const handleOrganizationContactChange = (e: ChangeEvent<HTMLInputElement>) => {
        setGroupInfo({ ...groupInfo, organization_contact: e.target.value })
    }

    const handleOrganizationEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setGroupInfo({ ...groupInfo, organization_email: e.target.value })
    }

    const handleOrganizationIntroChange = (e) => {
        setGroupInfo({ ...groupInfo, organization_intro: e.target.value })
    }


    const addGroup = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (isAddSaving) {
            return;
        }
        setIsAddSaving(true);
        postAPI(`/api/vending_machine_groups`, {
            parentUuid: addGroupStructure.parentUuid,
            name: addGroupStructure.name,
            description: addGroupStructure.description
        }).then((result) => {
            if (result.data.success) {
                updateGroupByProxy(result.data.vendingMachineGroup.uuid)
                closeAddGroupModal();
                props.reloadVendingMachineGroups();
            } else {
                window.alert(result.data.message)
            }
        }).catch((e) => {
            console.log(e)
            window.alert("Server error")
        }).finally(() => {
            setIsAddSaving(false);
        })
    }

    const removeGroup = (uuid: string) => {
        if (window.confirm("Once deleted, all vending machines below this group will be moved to its parent group.")) {
            if (isRemoveSaving) {
                return;
            }
            setIsRemoveSaving(true);
            postAPI(`/api/vending_machine_groups/${uuid}/delete`, {
            }).then((result) => {
                if (result.data.success) {
                    props.reloadVendingMachineGroups();
                } else {
                    window.alert(result.data.message)
                }
            }).catch((e) => {
                console.log(e)
                window.alert("Server error")
            }).finally(() => {
                setIsRemoveSaving(false);
            })
        }
    }

    const updateGroup = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (isEditSaving) {
            return;
        }
        setIsEditSaving(true);
        postAPI(`/api/vending_machine_groups/${editGroupStructure.uuid}`, {
            name: editGroupStructure.name,
            description: editGroupStructure.description
        }).then((result) => {
            if (result.data.success) {
                updateGroupByProxy(editGroupStructure.uuid);
                closeEditGroupModal();
                props.reloadVendingMachineGroups();
            } else {
                window.alert(result.data.message)
            }
        }).catch(() => {
            window.alert("Server error")
        }).finally(() => {
            setIsEditSaving(false);
        })
    }

    const updateGroupByProxy = (uuid) => {
        new vendingMachineGroupServiceApi().vendingMachineGroupServiceUpdateVendingMachineGroup(uuid, {
            organizationName: groupInfo.organization_name,
            organizationContact: groupInfo.organization_contact,
            organizationEmail: groupInfo.organization_email,
            organizationIntro: groupInfo.organization_intro,
        }).catch(err => {
            console.log(err)
            window.alert("update vending machine group error")
        }).finally(() => {
            resetGroupInfo();
        })
    }

    const getGroupInfo = (uuid) => {
        new vendingMachineGroupServiceApi().vendingMachineGroupServiceGetVendingMachineGroupInfo(uuid).then(res => {
            setGroupInfo({
                organization_name: res.data.organizationName as string,
                organization_contact: res.data.organizationContact as string,
                organization_email: res.data.organizationEmail as string,
                organization_intro: res.data.organizationIntro as string,
            })
        })
    }

    let history = useHistory();
    useEffect(() => {
        const treeData = formATree(props.allVendingMachineGroups)
        setTree(treeData);
    }, [props.allVendingMachineGroups])

    const navigateToScreenSavers = (uuid: string) => {
        history.push("/manage_screensavers/" + uuid + "/list")
    }

    const navigateToBingCombos = (uuid: string) => {
        history.push(`/bindingCombos/${uuid}/list`);
    }
    return (
        <Fragment>
            <Modal
                style={{
                    overlay: { zIndex: 1000 }, content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                    },
                }}
                ariaHideApp={false}
                isOpen={addGroupStructure.isModalOpen}
            >
                <h3>Please enter the group details</h3>

                <form onSubmit={addGroup}>
                    <div className="form-group">
                        <label>Name: </label>
                        <input className="form-control" type="text" value={addGroupStructure.name} autoFocus={true} onChange={handleNewGroupNameChange}></input>
                    </div>

                    <div className="form-group">
                        <label>Description: </label>
                        <input className="form-control" type="text" value={addGroupStructure.description} onChange={handleNewGroupDescriptionChange}></input>
                    </div>

                    <div className="form-group">
                        <label>Organization Name: </label>
                        <input className="form-control" type="text" value={groupInfo.organization_name} onChange={handleOrganizationNameChange}></input>
                    </div>
                    <div className="form-group">
                        <label>Organization Contact: </label>
                        <input className="form-control" type="text" value={groupInfo.organization_contact} onChange={handleOrganizationContactChange}></input>
                    </div>
                    <div className="form-group">
                        <label>Organization Email: </label>
                        <input className="form-control" type="text" value={groupInfo.organization_email} onChange={handleOrganizationEmailChange}></input>
                    </div>
                    <div className="form-group">
                        <label>Organization Intro: </label>
                        <textarea rows={2} className="form-control" value={groupInfo.organization_intro} onChange={handleOrganizationIntroChange} />
                    </div>

                    <BusyIndicator busy={isAddSaving} busyIndicator={<LoadingSpanner />}>
                        <input type={"submit"} className={"btn btn-success"} value="Add" />
                    </BusyIndicator>
                    <button onClick={closeAddGroupModal} className={"btn btn-default"}>Cancel</button>
                </form>
            </Modal>

            <Modal
                style={{
                    overlay: { zIndex: 1000 }, content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                    },
                }}
                ariaHideApp={false}
                isOpen={editGroupStructure.isModalOpen}
            >
                <h3>Please enter the group details</h3>

                <form onSubmit={updateGroup}>
                    <div className="form-group">
                        <label>Name: </label>
                        <input className="form-control" type="text" value={editGroupStructure.name} autoFocus={true} onChange={handleEditGroupNameChange}></input>
                    </div>

                    <div className="form-group">
                        <label>Description: </label>
                        <input className="form-control" type="text" value={editGroupStructure.description} onChange={handleEditGroupDescriptionChange}></input>
                    </div>

                    <div className="form-group">
                        <label>Organization Name: </label>
                        <input className="form-control" type="text" value={groupInfo.organization_name} onChange={handleOrganizationNameChange}></input>
                    </div>
                    <div className="form-group">
                        <label>Organization Contact: </label>
                        <input className="form-control" type="text" value={groupInfo.organization_contact} onChange={handleOrganizationContactChange}></input>
                    </div>
                    <div className="form-group">
                        <label>Organization Email: </label>
                        <input className="form-control" type="text" value={groupInfo.organization_email} onChange={handleOrganizationEmailChange}></input>
                    </div>
                    <div className="form-group">
                        <label>Organization Intro: </label>
                        <textarea rows={2} className="form-control" value={groupInfo.organization_intro} onChange={handleOrganizationIntroChange} />
                    </div>

                    <BusyIndicator busy={isEditSaving} busyIndicator={<LoadingSpanner />}>
                        <input type={"submit"} className={"btn btn-success"} value="Update" />
                    </BusyIndicator>
                    <button onClick={closeEditGroupModal} className={"btn btn-default"}>Cancel</button>
                </form>
            </Modal>

            <Helmet titleTemplate="%s - Vending on Track">
                <title>Vending Machine Groups</title>
            </Helmet>
            <section className="content-header">
                <h1>
                    Vending Machine Groups
                </h1>
            </section>

            <section className="content">
                <div className="box box-primary">
                    <div className="box-header with-border">
                        <h3 className="box-title">Vending Machine Groups</h3>
                    </div>
                    <div>
                        <SortableTree
                            canDrag={false}
                            treeData={tree}
                            onChange={treeData => setTree(treeData)}
                            isVirtualized={false}
                            generateNodeProps={row => ({
                                buttons: [
                                    <div className={"hover-button pull-right center-block"} style={{ minWidth: "350px" }}>
                                        <button className={"btn btn-default button-hide"} onClick={() => { openAddGroupModal(row.node.uuid) }}>Add</button>
                                        <BusyIndicator busy={isRemoveSaving} busyIndicator={<LoadingSpanner />}>
                                            <button className={"btn btn-default button-hide"} onClick={() => { removeGroup(row.node.uuid) }}>Remove</button>
                                        </BusyIndicator>
                                        <button className={"btn btn-default button-hide"} onClick={() => {
                                            openEditGroupModal(row.node.uuid, row.node.title, row.node.subtitle)
                                        }}>Edit
                                        </button>
                                        <button className={"btn btn-default button-hide"} onClick={() => {
                                            navigateToScreenSavers(row.node.uuid)
                                        }}>Savers
                                        </button>
                                        <button className={"btn btn-default button-hide"} onClick={() => {
                                            navigateToBingCombos(row.node.uuid)
                                        }}>Combos
                                        </button>
                                    </div>
                                ]
                            })}
                        />
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

export default withGlobalData(VendingMachineHierarchyManagement);
