import { md5 } from "./md5";
import { Link } from "react-router-dom";
import React from "react";
import { Authentication } from "./models";
import FreshChat from 'react-freshchat'

interface Props {
    handleClick(),

    authentication: Authentication
    pickingMenuOpen: boolean

    togglePickingMenu(e: any)

    reportMenuOpen: boolean

    toggleReportMenu(e: any)

    notificationCount: number | null
    productMenuOpen: boolean

    toggleProductMenu(e: any)

    companyMenuOpen: boolean

    toggleCompanyMenu(e: any)
}

export function AdminSidebar(props: Props) {
    return <aside onClick={props.handleClick} className="main-sidebar">
        <section className="sidebar">
            <div className="user-panel">
                <div className="pull-left image">
                    <img src={`https://www.gravatar.com/avatar/${md5(props.authentication.email)}?s=160`}
                        className="img-circle" alt="User" />
                </div>
                <div className="pull-left info">
                    <p>
                        {props.authentication.email}
                    </p>
                    <a href="/"><i className="fa fa-circle text-success"></i> Online</a>
                </div>
            </div>

            <ul className="sidebar-menu" data-widget="tree">
                <li className="header">MAIN NAVIGATION</li>
                <li className="treeview">
                    <Link to="/">
                        <i className="fa fa-dashboard"></i> <span>Dashboard</span>
                        <span className="pull-right-container">
                            <i className="fa fa-angle-left pull-right"></i>
                        </span>
                    </Link>
                </li>
                <li className="treeview">
                    <Link to="/buttons">
                        <i className="fa fa-check"></i> <span>Buttons</span>
                        <span className="pull-right-container">
                            <i className="fa fa-angle-left pull-right"></i>
                        </span>
                    </Link>
                </li>
                <li className="treeview">
                    <Link to="/vending_machine_list">
                        <i className="fa fa-inbox"></i> <span>Vending Machines</span>
                        <span className="pull-right-container">
                            <i className="fa fa-angle-left pull-right"></i>
                        </span>
                    </Link>
                </li>


                <li className="treeview">
                    <Link to="/refills">
                        <i className="fa fa-car"></i> <span>Refills</span>
                        <span className="pull-right-container">
                            <i className="fa fa-angle-left pull-right"></i>
                        </span>
                    </Link>
                </li>


                <li className="treeview">
                    <Link to="/forecast">
                        <i className="fa fa-cloud-sun-rain"></i> <span>AI Advisory</span>
                    </Link>
                </li>

                <li className={"treeview " + (props.pickingMenuOpen ? "menu-open" : "")}>
                    <a onClick={props.togglePickingMenu} href={"/"}>
                        <i className="fa fa-hand-grab-o"> </i>
                        <span>Picking</span>
                        <span className="pull-right-container">
                            <i className="fa fa-angle-left pull-right"> </i>
                        </span>
                    </a>

                    <ul className="treeview-menu">
                        <li>
                            <Link to="/pickings" title={"Create new Picking list"}>
                                <i className="fa fa-calendar-plus"></i> <span>New Picking</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/pickings/recent" title={"Revenue Report"}>
                                <i className="fa fa-clipboard-list"> </i> &nbsp;
                                <span>Picking list</span>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li className="treeview">
                    <Link to="/scheduling">
                        <i className="fa fa-calendar-check-o"></i> <span>Schedule</span>
                        <span className="pull-right-container">
                            <i className="fa fa-angle-left pull-right"></i>
                        </span>
                    </Link>
                </li>
                <li className="treeview">
                    <Link to="/inventories">
                        <i className="fa fa-warehouse"></i> <span>Warehouse & Inventory</span>
                        <span className="pull-right-container">
                            <i className="fa fa-angle-left pull-right"></i>
                        </span>
                    </Link>
                </li>

                <li className="treeview">
                    <Link to="/campaign">
                        <i className="fa fa-bell"></i> <span>Campaign</span>
                        <span className="pull-right-container">
                            <i className="fa fa-angle-left pull-right"></i>
                        </span>
                    </Link>
                </li>

                <li className={"treeview " + (props.reportMenuOpen ? "menu-open" : "")}>
                    <a onClick={props.toggleReportMenu} href={"/"}>
                        <i className="fa fa-pie-chart"> </i>
                        <span>Reports</span>
                        <span className="pull-right-container">
                            <i className="fa fa-angle-left pull-right"> </i>
                        </span>
                    </a>

                    <ul className="treeview-menu">
                        <li>
                            <Link to="/eod" title={"End of Day Detail Report"}>
                                <i className="fa fa-table"></i> <span>EOD Reports</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/reports/duration" title={"Revenue Report"}>
                                <i className="fas fa-calculator"> </i> &nbsp;
                                <span>Machine Revenue Report</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/reports/product" title={"Product Report"}>
                                <i className={"fas fa-coffee"}></i> &nbsp;
                                <span>Product Revenue Report</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/reports/category" title={"Product Report"}>
                                <i className={"fa fa-list-alt"}></i> &nbsp;
                                <span>Category Revenue Report</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/reports/product-movements" title={"Product Movements Report - for DEX machines"}>
                                <i className={"fas fa-store"}></i> &nbsp;
                                <span>Product Movements Report</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/reports/refunds" title={"Refunded Transactions Report"}>
                                <i className={"fas fa-retweet"}></i> &nbsp;
                                <span>Refunded Transactions Report</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/reports/credit_card" title={"Credit Card Report"}>
                                <i className={"fa fa-credit-card-alt"}></i> &nbsp;
                                <span>Credit Card Report</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/reports/credit_card_purchase" title={"Credit Card Purchase Report"}>
                                <i className={"fas fa-cash-register"}></i> &nbsp;
                                <span>Credit Card Purchase Report</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/reports/settlement" title={"VendCoin Settlement Report"}>
                                <i className={"fas fa-coins"}></i> &nbsp;
                                <span>VendCoin Settlement Report</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/payment/events" title={"Payment Events"}>
                                <i className={"fas fa-receipt"}></i> &nbsp;
                                <span>Payment Events</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/searchorder" title={"Search Order"}>
                                <i className={"fas fa-microscope"}></i> &nbsp;
                                <span>Search Order</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/kioskforce-orders" title={"KioskForce Order Report"}>
                                <i className={"fas fa-wallet"}></i> &nbsp;
                                <span>KioskForce Order Report</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/report-settings" title={"Report Settings"}>
                                <i className={"fas fa-cogs"}></i> &nbsp;
                                <span>Report Settings</span>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li className="treeview">
                    <Link to="/notifications">
                        <i className="fa fa-bell-o"></i> <span>Notifications and Alerts</span>
                        <span className="pull-right-container">
                            {props.notificationCount === 0 ?
                                null
                                :
                                <small
                                    className="label pull-right bg-red">{props.notificationCount}</small>
                            }
                        </span>
                    </Link>
                </li>


                <hr />

                <li className="treeview">
                    <Link to="/vending_machines">
                        <i className="fa fa-files-o"></i>
                        <span>Vending Machine Settings</span>
                        <span className="pull-right-container">
                            <span className="label label-primary pull-right"></span>
                        </span>
                    </Link>
                </li>
                <li className="treeview">
                    <Link to="/devices">
                        <i className="fa fa-signal"></i> <span>Telemetry Devices</span>
                        <span className="pull-right-container">
                            <small className="label pull-right bg-green"></small>
                        </span>
                    </Link>
                </li>


                <li className={"treeview " + (props.productMenuOpen ? "menu-open" : "")}>
                    <a onClick={props.toggleProductMenu} href={"/"}>
                        <i className="fa fa-th"> </i>
                        <span>Product</span>
                        <span className="pull-right-container">
                            <i className="fa fa-angle-left pull-right"> </i>
                        </span>
                    </a>
                    <ul className="treeview-menu">
                        <li>
                            <Link to="/products">
                                <i className="fa fa-th"></i> <span>Product Settings</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/categories">
                                <i className={"fa fa-list"}></i> <span>Category Settings</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/barcodes">
                                <i className="fa fa-barcode"></i> <span>Barcode Settings</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/product_recommendation_form">
                                <i className="fa fa-minus-square-o"></i> <span>Recommendation Forms</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/combos">
                                <i className="fa fa-cogs"></i> <span>Combos Settings</span>
                            </Link>
                        </li>
                    </ul>
                </li>


                <li className={"treeview " + (props.companyMenuOpen ? "menu-open" : "")}>
                    <a onClick={props.toggleCompanyMenu} href={"/"}>
                        <i className="fa fa-house-user"> </i>
                        <span>Company</span>
                        <span className="pull-right-container">
                            <i className="fa fa-angle-left pull-right"> </i>
                        </span>
                    </a>
                    <ul className="treeview-menu">
                        <li>
                            <Link to="/companies">
                                <i className="fa fa-th"></i> <span>Company Settings</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/company_users">
                                <i className={"fa fa-list"}></i> <span>Company User Management</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/company_driver_vending_machine_allocation">
                                <i className="fa fa-people-arrows"></i> <span>Allocate Vending Machines</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/company_clients">
                                <i className={"fa fa-list"}></i> <span>Company Clients Management</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/company_credit_card_surcharge_rules">
                                <i className={"fas fa-dollar-sign"}>&nbsp;&nbsp;&nbsp;</i> <span>Credit Card Surcharge Rules</span>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li className="treeview">
                    <Link to="/routings">
                        <i className="fa fa-bus"></i> <span>Routing Bundles</span>
                        <span className="pull-right-container">
                            <small className="label pull-right bg-green"></small>
                        </span>
                    </Link>
                </li>

                <li className="treeview">
                    <Link to="/vending_machine_hierarchy_management">
                        <i className="fa fa-sitemap"></i> <span>Vending Machine Groups</span>
                        <span className="pull-right-container">
                            <small className="label pull-right bg-green"></small>
                        </span>
                    </Link>
                </li>

                <hr />
                <li className="treeview">
                    <Link to="/security/logins">
                        <i className="fa fa-shield"></i> <span>Security</span>
                        <span className="pull-right-container">
                            <small className="label pull-right bg-green"></small>
                        </span>
                    </Link>
                </li>

            </ul>
            <FreshChat token={'31036c68-008d-47a2-bd78-f25319511c94'}
                onInit={widget => {
                    widget.user.setProperties({
                        email: props.authentication.email,
                        source: 'VOT'
                    });
                }}
            />
        </section>
    </aside>
}
