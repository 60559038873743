import React, { Component } from 'react';
import { getAPI, ManagementServiceApi, postAPI } from "./api";
import {
    CompanyUserRole,
    DailyRevenue,
    DexSalesInfoResponse, IngredientMapping,
    ProductMapping,
    Refill,
    Transaction,
    Tube,
    VendingMachine,
    VendingMachineFinancialStats,
    VirtualFinancialTransaction
} from './models';
import { withRouter } from "react-router-dom";
import { DisplayTime } from "./display_time";
import moment from 'moment';
import { Default, Mobile } from "./components/responsive";
import { IGlobalDataContext, withGlobalData } from "./contexts/global-data";
import { DriverVendingMachineStats } from "./driver_vending_machine_stats";
import { AdminVendingMachineStats } from "./admin_vending_machine_stats";
import { Helmet } from "react-helmet"
import { ReportVendingMachineStats } from "./report_vending_machine_stats";

interface Props extends IGlobalDataContext {
    isMobile: boolean
    match: any
}

interface TubeStatus {
}

interface State {
    vending_machine: VendingMachine | null
    stats: VendingMachineFinancialStats | null
    vending_machine_name: string | null,
    slugs: number,
    tube_status: Array<TubeStatus>,
    tube_amount: number,
    tube_count: number,
    tubes: Array<Tube>,

    transactions_page: number,
    transactions_pages: number,
    transactions: Array<Transaction>,

    last_dex_reading_at: number,
    dex_error: string | null,
    dex_sales: DexSalesInfoResponse | null,
    coils: Array<ProductMapping>,
    ingredientMappings: Array<IngredientMapping>
    tubeTimestamp: number | null,
    note_amount: number,
    note_count: number,
    refills: Array<Refill>
    virtual_financial_transactions: Array<VirtualFinancialTransaction>
    virtual_financial_transactions_total_page: number,
    online: boolean | null,
    dailyRevenues: Array<DailyRevenue>,
    reportDuration: number,
    dailyRevenueLoading: boolean,
}

class _VendingMachineStats extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            dailyRevenues: [],
            vending_machine: null,
            online: null,
            virtual_financial_transactions: [],
            virtual_financial_transactions_total_page: 0,
            refills: [],
            stats: null,
            vending_machine_name: null,
            slugs: 0,
            tube_status: [],
            tube_amount: 0.0,
            tube_count: 0,
            tubes: [],
            transactions: [],
            last_dex_reading_at: -1,
            dex_error: null,
            dex_sales: null,
            coils: [],
            ingredientMappings: [],
            tubeTimestamp: null,
            note_amount: 0,
            note_count: 0,
            transactions_page: 0,
            transactions_pages: 0,
            reportDuration: 28,
            dailyRevenueLoading: false,
        }
    }

    componentDidMount() {
        this.load_vending_machine_detail();
    }

    loadDexSales(lastDexId) {
        getAPI(`/api/dex_readings/${lastDexId}`).then((response) => {
            this.setState({ dex_sales: response.data });
        })
    }

    reload_coils() {
        getAPI(`/api/vending_machines/${this.props.match.params.uuid}/stock`).then((response) => {
            this.setState({
                coils: response.data.productMaps
            })
        });
    }

    
    reloadIngredientMappings() {
        getAPI(`/api/vending_machines/${this.props.match.params.uuid}/ingredient_mappings`).then((response) => {
            this.setState({
                ingredientMappings: response.data.ingredientMappingViewModels
            })
        });
    }

    manualBalance(coil: ProductMapping, newStockLevel: number) {
        postAPI(`/api/vending_machines/${this.props.match.params.uuid}/stock`, {
            id: coil.id,
            stock: newStockLevel
        }).then((result) => {
            if (result.data.success) {
                this.reload_coils();
            } else {
                alert(result.data.message);
            }
        }).catch((reason) => {
            alert(reason);
        });
    }

    ingredientMappingManualBalance(ingredientMapping: IngredientMapping, newStockLevel: number) {
        postAPI(`/api/vending_machines/${this.props.match.params.uuid}/ingredient_stock`, {
            id: ingredientMapping.id,
            stock: newStockLevel
        }).then((result) => {
            if (result.data.success) {
                this.reloadIngredientMappings();
            } else {
                alert(result.data.message);
            }
        }).catch((reason) => {
            alert(reason);
        });
    }

    load_vending_machine_detail() {
        const days = this.state.reportDuration
        this.reload_coils();
        this.reloadIngredientMappings();

        getAPI(`/api/vending_machines/${this.props.match.params.uuid}/refills`).then((response) => {
            if (response.data.refills) {
                this.setState({
                    refills: response.data.refills.content
                });
            }
        });

        getAPI(`/api/vending_machines/${this.props.match.params.uuid}/revenues/${this.state.reportDuration}`).then((response) => {
            if (days === this.state.reportDuration) {
                this.setState({
                    dailyRevenues: response.data.revenues
                }, () => { this.setState({ dailyRevenueLoading: false }) });
            }
        });

        getAPI(`/api/vending_machines/${this.props.match.params.uuid}`).then((response) => {
            if (response.data.success) {
                this.setState({ vending_machine: response.data.vendingMachine });
                this.setState({ last_dex_reading_at: response.data.lastDexReadingAt });
                if (response.data.lastDexId) {
                    this.loadDexSales(response.data.lastDexId);
                }

               
                this.setState({ vending_machine_name: response.data.vendingMachine.name });
                console.log(response);
                let tubes: Array<Tube> = [];

                let i;

                for (i = 0; i < response.data.vendingMachine.coinTubes.length; i++) {
                    const tube: Tube = {
                        value: response.data.vendingMachine.coinTubes[i].coinFaceValue,
                        coin_count: response.data.vendingMachine.coinTubes[i].coinCount,
                        full: response.data.vendingMachine.coinTubes[i].full,
                        faulty: response.data.vendingMachine.coinTubes[i].faulty
                    };

                    tubes.push(tube)
                }

                if (!response.data.vendingMachine.online) {
                    new ManagementServiceApi().machineManagementServiceListMachinesOnlineStatus({
                        vmids: [this.props.match.params.uuid]
                    }).then((data) => {
                        (data.data.machinesOnlineStatus || []).forEach((machineOnlineStatus) => {
                            if (machineOnlineStatus.vmid === this.props.match.params.uuid) {
                                const prevState = this.state;

                                this.setState({
                                    ...prevState,
                                    online: machineOnlineStatus.online || false
                                });
                            }
                        })
                    });
                }

                this.setState({
                    stats: {
                        coin_box_amount: response.data.vendingMachine.coinAmountInBox,
                        coin_box_count: response.data.vendingMachine.coinCountInBox,
                        timestamp: response.data.vendingMachine.financialTimestamp,

                        today_revenue: response.data.vendingMachine.todayRevenue,
                        salesCount: response.data.vendingMachine.transactionCount,
                        revenueSinceRefill: response.data.vendingMachine.revenueSinceRefill,
                        salesSinceRefill: response.data.vendingMachine.salesSinceRefill,
                        refill: response.data.vendingMachine.lastRefill,

                        weekRevenue: response.data.vendingMachine.weekRevenue,
                        weekSales: response.data.vendingMachine.weekSales,
                    },
                    slugs: response.data.vendingMachine.slugs,
                    tube_amount: response.data.vendingMachine.coinAmountInTube,
                    tube_count: response.data.vendingMachine.coinCountInTube,
                    note_count: response.data.vendingMachine.noteCount,
                    note_amount: response.data.vendingMachine.noteAmount,
                    tubeTimestamp: response.data.vendingMachine.tubeStatusTimestamp,
                    tubes: tubes,
                    online: response.data.vendingMachine.online
                });
            }
        });
    }

    handleDurationChange = (e) => {
        this.setState({ dailyRevenueLoading: true })
        const duration = Number(e.target.value)
        this.setState({ reportDuration: duration }, this.load_vending_machine_detail)
    }

    virtualPagin(page: number = 0) {
        getAPI(`/api/vending_machines/${this.props.match.params.uuid}/virtual_financial_transactions?page=${page}`).then((response) => {
            this.setState({
                virtual_financial_transactions_total_page: response.data.transactions.totalPages,
                virtual_financial_transactions: response.data.transactions.content
            })
        });
    }

    render() {
        let last_dex_reading = this.state.last_dex_reading_at == null ? 'Never' : (

            <DisplayTime timestamp={this.state.last_dex_reading_at / 1000} />
        );

        let refillTime = '';
        if (this.state.stats && this.state.stats.refill && this.state.stats.refill.createdTimestamp) {
            refillTime = moment.unix(this.state.stats.refill.createdTimestamp).toLocaleString();
        }

        const serviceUrl = this.state.vending_machine ? `/buttons/${this.state.vending_machine.uuid}` : '/buttons';

        const height = this.props.isMobile ? 240 : 100;

        if (this.props.me.role === CompanyUserRole.DRIVER.valueOf()) {
            return <DriverVendingMachineStats vending_machine_name={this.state.vending_machine_name}
                match={this.props.match}
                serviceUrl={serviceUrl}
                vending_machine={this.state.vending_machine}
                online={this.state.online}
                setManualBalance={this.manualBalance.bind(this)}
                reloadCoils={this.reload_coils.bind(this)}
                coils={this.state.coils}
                loadVendingMachineDetail={this.load_vending_machine_detail.bind(this)}
                refills={this.state.refills}
                refillTime={refillTime}
                ingredientMappings={this.state.ingredientMappings} />
        }
        if (this.props.me.role === CompanyUserRole.REPORT.valueOf()) {
            return <ReportVendingMachineStats vending_machine_name={this.state.vending_machine_name}
                match={this.props.match}
                vending_machine={this.state.vending_machine}
                online={this.state.online}
                setManualBalance={this.manualBalance.bind(this)}
                reloadCoils={this.reload_coils.bind(this)}
                coils={this.state.coils}
                loadVendingMachineDetail={this.load_vending_machine_detail.bind(this)}
                refills={this.state.refills}
                refillTime={refillTime}
                ingredientMappings={this.state.ingredientMappings} />
        }
        return (<div>
            <Helmet titleTemplate="%s - Vending on Track">
                <title>Vending Machine Stats</title>
            </Helmet>
            <AdminVendingMachineStats vending_machine_name={this.state.vending_machine_name}
                match={this.props.match}
                vending_machine={this.state.vending_machine}
                serviceUrl={serviceUrl}
                online={this.state.online}
                setManualBalance={this.manualBalance.bind(this)}
                setIngredientMappingManualBalance={this.ingredientMappingManualBalance.bind(this)}
                reloadCoils={this.reload_coils.bind(this)}
                coils={this.state.coils}
                loadVendingMachineDetail={this.load_vending_machine_detail.bind(this)}
                virtual_financial_transactions={this.state.virtual_financial_transactions}
                virtualPagin={this.virtualPagin.bind(this)}
                dex_error={this.state.dex_error}
                last_dex_reading={last_dex_reading}
                virtual_financial_transactions_total_page={this.state.virtual_financial_transactions_total_page}
                refills={this.state.refills}
                stats={this.state.stats}
                isMobile={this.props.isMobile}
                refillTime={refillTime}
                note_amount={this.state.note_amount}
                note_count={this.state.note_count}
                dailyRevenueLoading={this.state.dailyRevenueLoading}
                reportDuration={this.state.reportDuration}
                handleDurationChange={this.handleDurationChange.bind(this)}
                dailyRevenues={this.state.dailyRevenues}
                height={height}
                tubeTimestamp={this.state.tubeTimestamp}
                tubes={this.state.tubes}
                currencySymbol={this.props.me.mostRecentCompany.currencySymbol}
                ingredientMappings={this.state.ingredientMappings} />
        </div>)

    }
}

// @ts-ignore
const VendingMachineStatsRaw = withGlobalData(withRouter(_VendingMachineStats));


export const VendingMachineStats = (props: Props) => (
    <div>
        <Mobile>
            <VendingMachineStatsRaw {...props} isMobile={true} />
        </Mobile>
        <Default>
            <VendingMachineStatsRaw {...props} isMobile={false} />
        </Default>
    </div>
);
