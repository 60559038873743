import React, { useCallback, useEffect, useState } from "react";
import Select, { components } from 'react-select';
import ReactTooltip from 'react-tooltip';
import "bootstrap/dist/css/bootstrap.min.css";
import { vendingMachineGroupServiceApi, organisationSettingsServiceApi } from "./api";
import { V1ListGroupCombosResponse, TelemetryvendingMachinev1ListCombosResponse, V1GroupCombo } from "./telemetry/vending_machine/v1/typescript-axios";
import { Link, useParams } from "react-router-dom";
import { IGlobalDataContext, withGlobalData } from "./contexts/global-data";
import { formatCentsWithDecimalPoints } from "./helpers";

interface Props extends IGlobalDataContext {
}

const BindingCombosPage = (props: Props) => {
    const [groupCombosResponse, setGroupCombosResponse] = useState<V1ListGroupCombosResponse | null>(null);
    const [listCombosResponse, setListCombosResponse] = useState<TelemetryvendingMachinev1ListCombosResponse | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalMode, setModalMode] = useState<"add" | "edit" | "delete">("add");
    const [selectedCombo, setSelectedCombo] = useState<V1GroupCombo | null>(null);
    const [displayPriority, setDisplayPriority] = useState<number>(0);

    const { uuid } = useParams<{ uuid: string }>();

    const loadListGroupCombos = useCallback(() => {
        new vendingMachineGroupServiceApi().vendingMachineGroupServiceListGroupCombos(uuid)
            .then(res => setGroupCombosResponse(res.data))
            .catch(e => alert("Failed to load the group combo list"));
    }, [uuid]);

    const loadListCombos = useCallback(() => {
        new organisationSettingsServiceApi().organisationSettingsServiceListCombos({})
            .then(res => setListCombosResponse(res.data))
            .catch(e => alert("Failed to load the organization combo list."));
    }, []);

    useEffect(() => {
        loadListGroupCombos();
        loadListCombos();
    }, [loadListGroupCombos, loadListCombos]);

    const selectComboOptions = (listCombosResponse?.combos || [])
        .filter(combo => combo.comboUuid)
        .map(combo => ({
            value: combo.comboUuid,
            label: combo.internalName,
            alias: combo.displayName
        }));

    const CustomOption = (props) => {
        return (
            <components.Option {...props}>
                <div data-tip={props.data.alias}>
                    {props.data.label}
                </div>
                <ReactTooltip place="top" effect="solid" />
            </components.Option>
        );
    };

    const openAddModal = () => {
        setModalMode("add");
        setSelectedCombo(null);
        setDisplayPriority(0);
        setIsModalOpen(true);
    };

    const openEditModal = (combo: V1GroupCombo) => {
        setModalMode("edit");
        setSelectedCombo(combo);
        setDisplayPriority(combo.displayPriority || 0);
        setIsModalOpen(true);
    };

    const openDeleteModal = (combo: V1GroupCombo) => {
        setModalMode("delete");
        setSelectedCombo(combo);
        setIsModalOpen(true);
    };

    const handleSave = () => {
        if (modalMode === "add") {
            new vendingMachineGroupServiceApi().vendingMachineGroupServiceCreateGroupCombo(
                uuid ?? '',
                {
                    comboUuid: selectedCombo?.combo?.comboUuid,
                    displayPriority: displayPriority
                }).then(res => {
                    loadListGroupCombos();
                }).catch((error: any) => {
                    const errorMessage = error.response
                        ? error.response.data?.message
                        : error.message || 'Unknown error';
                    alert(`Failed to create the combo: ${errorMessage}`);
                });
        } else if (modalMode === "edit") {
            new vendingMachineGroupServiceApi().vendingMachineGroupServiceUpdateGroupCombo(
                selectedCombo?.groupComboUuid ?? '',
                {
                    displayPriority: displayPriority
                }).then(() => {
                    loadListGroupCombos();
                }).catch((error: any) => {
                    const errorMessage = error.response
                        ? error.response.data?.message
                        : error.message || 'Unknown error';
                    alert(`Failed to update the combo. Error: ${errorMessage}`);
                });
        }

        setIsModalOpen(false);
    };

    const handleDelete = () => {
        console.log('selectedCombo>>>>>>', selectedCombo);
        new vendingMachineGroupServiceApi().vendingMachineGroupServiceDeleteGroupCombo(selectedCombo?.groupComboUuid ?? '').then(() => {
            setIsModalOpen(false);
            loadListGroupCombos();
        }).catch((error: any) => {
            const errorMessage = error.response
                ? error.response.data?.message
                : error.message || 'Unknown error';
            alert(`Failed to delete the combo. Error: ${errorMessage}`);
        });
    };

    return (
        <div className="content mt-4">
            <h2>Binding Group Combos</h2>
            <div className={"well"}>
                <button className="btn btn-success" onClick={openAddModal}>
                    + Add Group Combo
                </button>
            </div>

            <div className="box box-success well">
                <table className="table table-striped table-hover">
                    <thead className="table-dark">
                        <tr>
                            <th>Combo InternalName</th>
                            <th>Combo DisplayName</th>
                            <th>Price</th>
                            <th>Display Priority</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {groupCombosResponse?.groupCombos?.length === 0 ? (
                            <tr>
                                <td colSpan={3} className="text-center">No combos available.</td>
                            </tr>
                        ) : (
                            groupCombosResponse?.groupCombos?.map((combo) => (
                                <tr key={combo.groupComboUuid}>
                                    <td><Link to={{
                                                            pathname: `/combos/${combo.combo?.comboUuid}/settings`,
                                                            state: { combo },
                                    }}>{combo.combo?.internalName}</Link></td>
                                    <td>{combo.combo?.displayName}</td>
                                    <td>{formatCentsWithDecimalPoints(combo.combo?.price ?? 0, props.me.mostRecentCompany.currencyDecimalPoints) }</td>
                                    <td>{combo.displayPriority}</td>
                                    <td>
                                        <button className="btn btn-warning me-2" style={{ marginRight: "0.5em" }} onClick={() => openEditModal(combo)}>
                                            Edit
                                        </button>
                                        <button className="btn btn-danger" onClick={() => openDeleteModal(combo)}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="modal show d-block" style={{ background: "rgba(0,0,0,0.5)" }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {modalMode === "add" ? "Add Group Combo" : modalMode === "edit" ? "Edit Group Combo" : "Delete Group Combo"}
                                </h5>
                            </div>
                            <div className="modal-body">
                                {modalMode === "delete" ? (
                                    <p>Are you sure you want to delete <strong>{selectedCombo?.combo?.internalName ?? ''}</strong>?</p>
                                ) : (
                                    <>
                                        <div className="form-group">
                                            <label className="font-weight-bold">Combo </label>
                                            <Select
                                                className="mt-2"
                                                options={selectComboOptions}
                                                components={{ Option: CustomOption }}
                                                value={selectComboOptions.find(
                                                    (p) => p.value === selectedCombo?.combo?.comboUuid
                                                ) || null}
                                                isDisabled={modalMode === "edit"}
                                                onChange={(selectedOption) => {
                                                    if (selectedOption && modalMode === "add") {
                                                        setSelectedCombo({
                                                            groupComboUuid: "",
                                                            combo: {
                                                                comboUuid: selectedOption.value,
                                                                internalName: selectedOption.label,
                                                                displayName: listCombosResponse?.combos?.find(c => c.comboUuid === selectedOption.value)?.displayName || "",
                                                                price: 0,
                                                                description: "",
                                                                imageUrl: "",
                                                                products: [],
                                                                createdAt: "",
                                                                updatedAt: ""
                                                            },
                                                            displayPriority: displayPriority
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="form-group mt-3">
                                            <label className="font-weight-bold">Display Priority</label>
                                            <input
                                                type="number"
                                                className="form-control mt-2"
                                                value={displayPriority}
                                                onChange={(e) => setDisplayPriority(Number(e.target.value))}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-secondary" onClick={() => setIsModalOpen(false)}>
                                    Close
                                </button>
                                {modalMode === "delete" ? (
                                    <button className="btn btn-danger" onClick={handleDelete}>
                                        Confirm Delete
                                    </button>
                                ) : (
                                    <button className="btn btn-primary" onClick={handleSave}>
                                        Save
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default withGlobalData(BindingCombosPage);
