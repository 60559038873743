import {VendingMachine} from "./models";
import {getAPI} from "./api";

const OPTMIZE_FACTOR = 7

export function getAllVendingMachines(): Promise<Array<VendingMachine>> {
    let waits: Array<Promise<Array<VendingMachine>>> = [];

    for (let i = 0; i < OPTMIZE_FACTOR; i++) {
        const w = getAPI(`/api/vending_machines?mod=${OPTMIZE_FACTOR}&seq=${i}&optimize=1`).then((response) => {
            if (response.data.success) {
                return response.data.vending_machines
            } else {
                throw new Error("Failed to retrieve vending machine list from server")
            }
        })
        waits.push(w)
    }

    return Promise.all(waits).then((responses) => {
        let allVms: Array<VendingMachine> = [];
        responses.forEach((vendingMachines) => {
            vendingMachines.forEach((vm) => {
                allVms.push(vm);
            })
        })
        return allVms;
    })
}

