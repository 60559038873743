import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import {ProductMapping, VendingMachine, VendingMachinesWithLoadingState} from "./models";
import {DisplayTime} from "./display_time";
import DataTable from 'react-data-table-component';
import MediaQuery from 'react-responsive'
import {VendingMachineLink} from "./components/vending_machine_link";
import FillLevel from "./fill_level";
import {FormatMoney} from "./format_money";
import {FormatMoneyWithFlexibleSignSize} from "./format_money_without_style";
import {CSVLink} from 'react-csv';
import formatUtilPrice from './format_until_price';
import {IGlobalDataContext, withGlobalData} from './contexts/global-data';
import moment from 'moment';


interface Props extends IGlobalDataContext {
    vending_machines: VendingMachinesWithLoadingState
    isLinkedToSettings: boolean
    withFilter?: boolean
}

interface State {
    vendingMachineSelected?: VendingMachine
    filterText?: string
    onlineOnly: boolean

}

class VendingMachinesTableRaw extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {onlineOnly: false}
    }

    updateFilter(event) {
        this.setState({filterText: event.target.value});
    }

    onSelect(row: VendingMachine) {
        this.setState({
            vendingMachineSelected: row
        });
    }

    toggleOnlineVisibility(event) {
        this.setState({onlineOnly: !this.state.onlineOnly})
    }


    prepareCsvData(vendingMachines) {
        return vendingMachines.map((vendingMachine) => {
            const lastRefillUTC = vendingMachine.lastRefill
                ? moment(vendingMachine.lastRefill.createdDate).utc()
                : null;
            const lastTransactionUTC = vendingMachine.lastTransaction
                ? moment(vendingMachine.lastTransaction.createdDate).utc()
                : null;
            const lastSalesUTC = vendingMachine.lastSales
                ? moment(vendingMachine.lastSales.createdDate).utc()
                : null;

            const lastRefillLocal = lastRefillUTC
                ? lastRefillUTC.clone().tz(this.props.me.timezone)
                : null;
            const lastTransactionLocal = lastTransactionUTC
                ? lastTransactionUTC.clone().tz(this.props.me.timezone)
                : null;
            const lastSalesLocal = lastSalesUTC
                ? lastSalesUTC.clone().tz(this.props.me.timezone)
                : null;

            const vendingMachineAttributes = vendingMachine.vendingMachineAttributes ? vendingMachine.vendingMachineAttributes : {};

            const data = {
                name: vendingMachine.name,
                todayRevenue: formatUtilPrice(vendingMachine.todayRevenue ?? 0, this.props.me.mostRecentCompany.currencyDecimalPoints),
                lowestStock: vendingMachine.lowestStock
                    ? `${vendingMachine.lowestStock.product_name} - ${vendingMachine.lowestStock.currentStock}/${vendingMachine.lowestStock.restock}`
                    : '',
                currentStockCount: vendingMachine.currentStockCount,
                standardStockCount: vendingMachine.standardStockCount,
                fillLevel: vendingMachine.fillLevel ? `${vendingMachine.fillLevel}%` : `-`,
                source: vendingMachine.salesInfoSource,
                collectableCash: formatUtilPrice(vendingMachine.coinAmountInBox + vendingMachine.noteAmount, this.props.me.mostRecentCompany.currencyDecimalPoints),
                sales: vendingMachine.salesAndRevenue ? `${vendingMachine.salesAndRevenue[0]}` : '',
                revenue: vendingMachine.salesAndRevenue ? `${formatUtilPrice(vendingMachine.salesAndRevenue[1], this.props.me.mostRecentCompany.currencyDecimalPoints)}` : '',
                creation_time_utc: lastRefillUTC ? lastRefillUTC.format('YYYY-MM-DDTHH:mm:ss[Z]') : '',
                creation_time_local: lastRefillLocal ? lastRefillLocal.format() : '',
                creation_utc_time: lastRefillUTC ? lastRefillUTC.format('HH:mm:ss') : '',
                creation_local_date: lastRefillLocal ? lastRefillLocal.format('YYYY/MM/DD') : '',
                creation_local_time: lastRefillLocal ? lastRefillLocal.format('HH:mm:ss') : '',
                lastTransactionUTC: lastTransactionUTC ? lastTransactionUTC.format('YYYY-MM-DDTHH:mm:ss[Z]') : '',
                lastTransactionLocal: lastTransactionLocal ? lastTransactionLocal.format() : '',
                lastTransactionUTCTime: lastTransactionUTC ? lastTransactionUTC.format('HH:mm:ss') : '',
                lastTransactionLocalDate: lastTransactionLocal ? lastTransactionLocal.format('YYYY/MM/DD') : '',
                lastTransactionLocalTime: lastTransactionLocal ? lastTransactionLocal.format('HH:mm:ss') : '',
                lastSalesUTC: lastSalesUTC ? lastSalesUTC.format('YYYY-MM-DDTHH:mm:ss[Z]') : '',
                lastSalesLocal: lastSalesLocal ? lastSalesLocal.format() : '',
                lastSalesUTCTime: lastSalesUTC ? lastSalesUTC.format('HH:mm:ss') : '',
                lastSalesLocalDate: lastSalesLocal ? lastSalesLocal.format('YYYY/MM/DD') : '',
                lastSalesLocalTime: lastSalesLocal ? lastSalesLocal.format('HH:mm:ss') : '',
                vendingMachineAttribute1: vendingMachineAttributes.attribute_1 || "",
                vendingMachineAttribute2: vendingMachineAttributes.attribute_2 || "",
                vendingMachineAttribute3: vendingMachineAttributes.attribute_3 || "",
                vendingMachineAttribute4: vendingMachineAttributes.attribute_4 || "",
            };

            return data;
        });
    }

    render() {
        let vendingMachines = this.props.vending_machines.vendingMachines.map((vendingMachine) => {
            let missing = 0;
            if ((vendingMachine.standardStockCount != null) && (null != vendingMachine.currentStockCount)) {
                missing = vendingMachine.standardStockCount - vendingMachine.currentStockCount;
            }

            let lowestStockMissing = 0;
            if (vendingMachine.lowestStock != null) {
                const lowestStock = vendingMachine.lowestStock;
                if ((lowestStock.currentStock != null) && (lowestStock.restock != null)) {
                    const missing = lowestStock.restock - lowestStock.currentStock;
                    lowestStockMissing = missing;
                }
            }

            const salesSinceRefill = vendingMachine.salesSinceRefill
            const revenueSinceRefill = vendingMachine.revenueSinceRefill
            const salesAndRevenueSinceRefill = [salesSinceRefill, revenueSinceRefill]
            const collectableCash = vendingMachine.coinAmountInBox + vendingMachine.noteAmount

            return {
                ...vendingMachine,
                missing: missing,
                lowestStockMissing: lowestStockMissing,
                salesAndRevenue: salesAndRevenueSinceRefill,
                collectableCash: collectableCash
            }
        });

        if (this.state.vendingMachineSelected) {
            if (this.props.isLinkedToSettings) {
                return <Redirect to={`/vending_machines/${this.state.vendingMachineSelected.uuid}/setting`}
                                 push={true}/>;
            } else {
                return <Redirect to={`/vending_machines/${this.state.vendingMachineSelected.uuid}`} push={true}/>;
            }
        }

        if (this.state.filterText) {
            vendingMachines = vendingMachines.filter((vendingMachine) => {
                if ((vendingMachine.name.toLowerCase().search((this.state.filterText || "").toLowerCase()) >= 0) || ((vendingMachine.address || '').toLowerCase().search((this.state.filterText || '').toLowerCase()) >= 0)) {
                    return true;
                }

                return false;
            });
        }

        if (this.state.onlineOnly) {
            vendingMachines = vendingMachines.filter((vendingMachine) => {
                return vendingMachine.online;
            })
        }

        const mobileColumns = [
            {
                name: "Name",
                selector: "name",
                compact: true,
                sortable: true,
                ignoreRowClick: true,
                cell: (vendingMachine: VendingMachine) => {
                    if (this.props.isLinkedToSettings) {
                        return (
                            <div>
                                <VendingMachineLink vendingMachine={vendingMachine} setting={true}/>
                            </div>);
                    } else {
                        return (
                            <div>
                                <VendingMachineLink vendingMachine={vendingMachine} setting={false}/>
                            </div>);
                    }
                }
            },
            {
                name: "Today's Revenue",
                compact: true,
                sortable: true,
                selector: "todayRevenue",
                format: (vendingMachine: VendingMachine) => {
                    return <FormatMoneyWithFlexibleSignSize cents={vendingMachine.todayRevenue}/>;
                }
            },
            {
                name: "last transaction",
                sortable: true,
                compact: true,
                selector: "lastTransaction.timestamp",
                format: ((vendingMachine: VendingMachine) => {
                    return vendingMachine.lastTransaction ?
                        (<DisplayTime timestamp={vendingMachine.lastTransaction.timestamp}/>) : null
                })
            },
        ];

        const columns = [
            {
                name: "Name",
                selector: "name",
                sortable: true,
                ignoreRowClick: true,
                width: "200px",
                cell: (vendingMachine: VendingMachine) => {
                    if (this.props.isLinkedToSettings) {
                        return (
                            <div>
                                <VendingMachineLink vendingMachine={vendingMachine} setting={true}/>
                                {vendingMachine.creditCardReaderOnTestEnv && <div>
                                    <span style={{fontSize: "85%", color: "red"}}>
                                        card reader on test environment
                                    </span>
                                </div>}
                                <div>
                                    <span style={{fontSize: "85%"}}>{vendingMachine.address}</span>
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div>
                                <VendingMachineLink vendingMachine={vendingMachine} setting={false}/>
                                {vendingMachine.creditCardReaderOnTestEnv && <div>
                                    <span style={{fontSize: "85%", color: "red"}}>
                                        card reader on test environment
                                    </span>
                                </div>}
                                <div>
                                    <span style={{fontSize: "85%"}}>{vendingMachine.address}</span>
                                </div>
                            </div>
                        );
                    }
                }
            },
            {
                name: "Today's Revenue",
                sortable: true,
                selector: "todayRevenue",
                format: (vendingMachine: VendingMachine) => {
                    return <FormatMoneyWithFlexibleSignSize cents={vendingMachine.todayRevenue}/>;
                }
            },
            {
                name: "Last Refill Time",
                sortable: true,
                selector: "lastRefill.createdTimestamp",
                format: ((vendingMachine: VendingMachine) => {
                    return vendingMachine.lastRefill ?
                        (<DisplayTime timestamp={vendingMachine.lastRefill.createdTimestamp}/>) : null
                })
            },
            {
                name: "Last Transaction",
                sortable: true,
                selector: "lastTransaction.timestamp",
                format: ((vendingMachine: VendingMachine) => {
                    if (vendingMachine.lastTransaction) {
                        return (<span><DisplayTime timestamp={vendingMachine.lastTransaction.timestamp}/></span>);
                    }

                    return null;
                })
            },
            {
                name: "Last Sales",
                sortable: true,
                selector: "lastSales.timestamp",
                format: ((vendingMachine: VendingMachine) => {
                    if (vendingMachine.lastSales) {
                        return (<span><DisplayTime timestamp={vendingMachine.lastSales.timestamp}/></span>);
                    }

                    return null;
                })
            },
            {
                name: "Lowest Stock",
                sortable: true,
                selector: "lowestStockMissing",
                format: ((vendingMachine) => {
                    if (vendingMachine.lowestStock == null) {
                        return '-';
                    }
                    const productMapping: ProductMapping = vendingMachine.lowestStock;
                    console.log(productMapping);
                    return `${productMapping.product_name} - ${productMapping.currentStock}/${productMapping.restock} `;
                })
            },
            {
                name: "Stock Level",
                sortable: true,
                selector: "missing",
                format: ((vendingMachine) => {
                    return `${vendingMachine.currentStockCount}/${vendingMachine.standardStockCount}`;
                })
            },
            {
                name: "Fill Level",
                sortable: true,
                selector: "fillLevel",
                format: ((vendingMachine) => {
                    return <FillLevel vendingMachine={vendingMachine}/>
                })
            },
            {
                name: "Source",
                maxWidth: "60px",
                minWidth: "60px",
                selector: "salesInfoSource"
            },
            {
                name: "Collectable Cash",
                selector: "collectableCash",
                sortable: true,
                format: ((vendingMachine) => {
                    return <FormatMoney cents={vendingMachine.collectableCash}/>
                })
            },
            {
                name: "Sales Since Last Refill",
                maxWidth: "200px",
                minWidth: "200px",
                selector: "salesAndRevenue[0]",
                sortable: true,
                format: ((vendingMachine) => {
                    return <p>
                        Sales: {vendingMachine.salesAndRevenue[0]}
                        &nbsp;&nbsp;
                        Revenue: <FormatMoney cents={vendingMachine.salesAndRevenue[1]}/>
                    </p>
                })
            }
        ];

        let filter: any = (
            <div className="row">
                <div className="col-xl-6 col-md-12" style={{paddingRight: '5px'}}>
                    <input placeholder={"Type in here for filter"} className={"form-control"}
                           onChange={this.updateFilter.bind(this)} value={this.state.filterText}/>
                </div>
                <div className="col-xl-4 col-md-12">
                    <label style={{fontSize: "15px", verticalAlign: "middle"}}>

                        <input checked={this.state.onlineOnly} onChange={this.toggleOnlineVisibility.bind(this)}
                               type="checkbox" name="onlineOnly"/>
                        &nbsp; Hide offline vending machines
                    </label>
                </div>

                <div className="col-xl-2 col-md-12">
                    <CSVLink
                        data={this.prepareCsvData(vendingMachines)}
                        headers={[
                            {label: 'Vending Machine Name', key: 'name'},
                            {label: 'Today\'s Revenue', key: 'todayRevenue'},
                            {label: 'Lowest Stock', key: 'lowestStock'},
                            {label: 'CurrentStockCount', key: 'currentStockCount'},
                            {label: 'StandardStockCount', key: 'standardStockCount'},
                            {label: 'Fill Level', key: 'fillLevel'},
                            {label: 'Source', key: 'source'},
                            {label: 'Collectable Cash', key: 'collectableCash'},
                            {label: 'Sales Since Last Refill', key: 'sales'},
                            {label: 'Revenue Since Last Refill', key: 'revenue'},
                            {label: 'Last Refill Time (Local)', key: 'creation_time_local'},
                            {label: 'Last Refill Time (UTC)', key: 'creation_time_utc'},
                            {label: 'Last Refill Time (Local Date)', key: 'creation_local_date'},
                            {label: 'Last Refill Time (Local Time)', key: 'creation_local_time'},
                            {label: 'Last Transaction (Local)', key: 'lastTransactionLocal'},
                            {label: 'Last Transaction (UTC)', key: 'lastTransactionUTC'},
                            {label: 'Last Transaction (Local Date)', key: 'lastTransactionLocalDate'},
                            {label: 'Last Transaction (Local Time)', key: 'lastTransactionLocalTime'},
                            {label: 'Last Sales (Local)', key: 'lastSalesLocal'},
                            {label: 'Last Sales (UTC)', key: 'lastSalesUTC'},
                            {label: 'Last Sales (Local Date)', key: 'lastSalesLocalDate'},
                            {label: 'Last Sales (Local Time)', key: 'lastSalesLocalTime'},
                            {label: 'Vending Machine Attribute 1', key: 'vendingMachineAttribute1'},
                            {label: 'Vending Machine Attribute 2', key: 'vendingMachineAttribute2'},
                            {label: 'Vending Machine Attribute 3', key: 'vendingMachineAttribute3'},
                            {label: 'Vending Machine Attribute 4', key: 'vendingMachineAttribute4'},
                        ]}
                        filename={'vending_machines.csv'}
                        className="btn btn-primary"
                    >
                        Download CSV
                    </CSVLink>
                </div>
            </div>);

        if (this.props.withFilter !== true) {
            filter = "Vending Machines"
        }

        if (this.props.vending_machines.loading) {
            return (<div className="callout callout-info">
                <h4>Loading vending machines...</h4>

                <p>We are listing all your vending machines. It won't be long.</p>
            </div>);
        }

        return (
            <div className="box box-success">
                <div className="box-body no-padding">
                    <MediaQuery minDeviceWidth={768}>
                        <DataTable
                            pagination={true}
                            paginationPerPage={100}
                            paginationRowsPerPageOptions={[20, 100, 200, 1000, 5000]}
                            responsive={true}
                            highlightOnHover={true}
                            pointerOnHover={false}
                            defaultSortField="name"
                            title={filter}
                            columns={columns}
                            data={vendingMachines}
                        />
                    </MediaQuery>
                    <MediaQuery maxDeviceWidth={768}>
                        <DataTable
                            pagination={true}
                            responsive={true}
                            highlightOnHover={true}
                            pointerOnHover={false}
                            defaultSortField="name"
                            title={filter}
                            columns={mobileColumns}
                            data={vendingMachines}
                            expandableRows={true}
                            expandableRowsComponent={<ExpanableRow data=""/>}
                        />
                    </MediaQuery>
                </div>
            </div>
        )
    }
}

interface IExpanableRowProps {
    data: any
}

const ExpanableRow: React.SFC<IExpanableRowProps> = ({data}) => {
    return (<div style={{padding: '10px'}}>
        Last refill: {data.lastRefill ? <DisplayTime timestamp={data.lastRefill.createdTimestamp}/> : ''}
        <br/>
        Lowest Stock: {data.lastRefill ? data.lowestStockMissing : ''}

    </div>)
};

const VendingMachinesTable = withGlobalData(VendingMachinesTableRaw);
export {VendingMachinesTable};
