import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, Route, Switch } from "react-router-dom";
import { getAPI, organisationSettingsServiceApi } from "./api";
import { TelemetryvendingMachinev1ListCombosResponse, TelemetryvendingMachinev1Combo } from "./telemetry/vending_machine/v1/typescript-axios/api";
import { Helmet } from "react-helmet";
import { Product, productPicture } from "./models";
import { IGlobalDataContext, withGlobalData } from "./contexts/global-data";
import formatUtilPrice from "./format_until_price";
import { formatMoneyWithoutSymbol } from "./helpers";
import moment from "moment";


interface Props extends IGlobalDataContext {
    match: any;
    product_list?: Product[];
}


const ListCombos = (props: Props) => {
    const [listCombosResponse, setListCombosResponse] = useState<TelemetryvendingMachinev1ListCombosResponse | null>(null);
    const [productList, setProductList] = useState<Product[]>(props.product_list || []);
    const ProductStatsExp = (data, product) => {
        console.log('data>>>>>>>', data);
        const combos = data.data as TelemetryvendingMachinev1Combo;

        const getProductName = (productUuid?: string) => {
            return productList.find(prod => prod.uuid === productUuid)?.name || 'Unknown';
        };

        const getProducUrl = (productUuid?: string) => {
            return productPicture(productList.find(prod => prod.uuid === productUuid) ?? null);
        };

        return (
            <div className="mt-4 p-4 bg-light rounded shadow-sm">
                <table className="table table-bordered table-striped table-hover">
                    <thead className="table-primary">
                        <tr>
                            <th>Product Name</th>
                            <th>Image</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {combos.products?.map((product) => (
                            <tr key={product.productUuid}>
                                <td>{getProductName(product.productUuid)}</td>
                                <td>
                                    <img
                                        src={getProducUrl(product.productUuid)}
                                        alt={getProductName(product.productUuid)}
                                        style={{ height: "2em", objectFit: "cover" }}
                                    />
                                </td>
                                <td>{formatUtilPrice(product.price ?? 0, props.me.mostRecentCompany.currencyDecimalPoints)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };


    const loadListCombos = () => {
        new organisationSettingsServiceApi().organisationSettingsServiceListCombos({}).then(
            res => {
                setListCombosResponse(res.data);
            }).catch((e) => {
                alert("organisationSettingsServiceListCombos Error" + e);
            });
    }


    useEffect(() => {
        if (!props.product_list) {
            getAPI('/api/products').then((response) => {
                if (response.data.success) {
                    setProductList(response.data.products);
                }
            });
        }
    }, [props.product_list]);


    useEffect(() => {
        loadListCombos();
    }, []);


    const handleRemove = async (comboUuid: string) => {
        if (window.confirm(`Delete ${comboUuid}?`)) {
            try {
                await new organisationSettingsServiceApi().organisationSettingsServiceDeleteCombo(comboUuid);
                loadListCombos();
            } catch (error: unknown) {
                console.error("Failed to delete combo:", error);

                let errorMessage = "An unknown error occurred";

                if (error instanceof Error) {
                    errorMessage = error.message;
                } else if (typeof error === "string") {
                    errorMessage = error;
                } else if (typeof error === "object" && error !== null && "message" in error) {
                    errorMessage = String((error as { message: string }).message);
                }

                alert(`Failed to delete the combo. Error: ${errorMessage}`);
            }
        }
    };


    const longColumns = [
        {
            name: "Combo ID",
            selector: "combo_uuid",
            sortable: true,
            width: "150px",
            cell: (combo: TelemetryvendingMachinev1Combo) => (
                <div>
                    {combo.comboUuid}
                    &nbsp;
                    <Link to={{
                        pathname: `/combos/${combo.comboUuid}/settings`,
                        state: { combo },
                    }}>Details...</Link>
                </div>
            ),
        },
        {
            name: "Internal Name",
            selector: "internal_name",
            sortable: true,
            cell: (combo: TelemetryvendingMachinev1Combo) => combo.internalName,
        },
        {
            name: "Display Name",
            selector: "display_name",
            sortable: true,
            cell: (combo: TelemetryvendingMachinev1Combo) => combo.displayName,
        },
        {
            name: "Price",
            selector: "price",
            sortable: true,
            cell: (combo: TelemetryvendingMachinev1Combo) => formatMoneyWithoutSymbol(combo.price ?? 0, props.me.mostRecentCompany.currencyDecimalPoints)
            ,
        },
        // {
        //     name: "Description",
        //     selector: "description",
        //     sortable: false,
        //     cell: (combo: Combo) => combo.description,
        // },
        {
            name: "Created At",
            selector: "created_at",
            sortable: true,
            cell: (combo: TelemetryvendingMachinev1Combo) => moment(combo.createdAt).tz(props.me.timezone).toString(),
        },
        {
            name: "Updated At",
            selector: "updated_at",
            sortable: true,
            cell: (combo: TelemetryvendingMachinev1Combo) => moment(combo.updatedAt).tz(props.me.timezone).toString(),
        },
        {
            name: "Actions",
            sortable: false,
            ignoreRowClick: true,
            cell: (combo: TelemetryvendingMachinev1Combo) => (
                <div>
                    <button
                        className={"btn btn-danger"}
                        onClick={(event) => {
                            event.preventDefault();
                            handleRemove(combo.comboUuid ?? '');
                            return false;
                        }}
                    >
                        Delete
                    </button>
                </div>
            ),
            width: "150px",
        },
    ];

    return (
        <Fragment>
            <section className="content">
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Combos Settings</title>
                </Helmet>
                <h1>Combos Settings</h1>
                <div className={"well"}>
                    <Switch>
                        <Route>
                            <div>
                                <Link to="/combos/new" className="btn btn-success">Add Combo</Link>
                            </div>
                        </Route>
                    </Switch>
                </div>
                <DataTable
                    selectableRows
                    highlightOnHover
                    pointerOnHover
                    defaultSortField="combo_uuid"
                    defaultSortAsc={false}
                    title={"Combos"}
                    columns={longColumns}
                    data={listCombosResponse?.combos ?? []}
                    expandableRows={true}
                    expandableRowsComponent={<ProductStatsExp />}
                />
            </section>
        </Fragment>
    );
};

export default withGlobalData(ListCombos);
