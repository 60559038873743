import React from "react";
import {CoilProductStats, ProductMapping, VendingMachine} from "./models";
import DataTable from 'react-data-table-component';
import MediaQuery from 'react-responsive'
import {ExpanableRow} from "./expanable_row";
import {V1ClawMachineDispenseRecord, V1ClawMachineStock} from "./telemetry/vending_machine/v1/typescript-axios";

interface Props {
    vendingMachine: VendingMachine
    setAggregatedByProduct(boolean)
    aggregatedByProduct: boolean
    aggregatedColumns: Array<any>
    products: Array<CoilProductStats>
    columns: Array<any>
    coils: Array<ProductMapping>
    stocks: Array<V1ClawMachineStock>
    clawMachineStockColumns: Array<any>
    mobileColumns: Array<any>
    manualBalance(coil: ProductMapping, newStock: number);
    clawMachineDispenseRecords: Array<V1ClawMachineDispenseRecord>
    clawMachineDispenseRecordsColumns: Array<any>
}

export function DriverCoilStatsTable(props: Props) {
    return <div className={"box"}>

        <div className="hidden-xs box-header">
            <div className="text">
                This section lists all the columns/coils for vending machines.
            </div>

            <div className="alert alert-default">
                <b>Hint: </b>
                Click product "Current Stock" to adjust par level manually
            </div>
        </div>

        <div className={"box-body content"}>
            {props.vendingMachine.vendingMachineType !== 'CLAW_MACHINE' ? props.aggregatedByProduct ?
                <DataTable key={1}
                           title={"Product Inventory Stats"}
                           highlightOnHover={true}
                           defaultSortField={"Product"}
                           columns={props.aggregatedColumns}
                           data={props.products}
                />
                :
                <div>
                    <MediaQuery minDeviceWidth={768}>
                        <div>
                            <DataTable key={2}
                                       title={"Inventory"}
                                       highlightOnHover={false}
                                       pointerOnHover={false}
                                       defaultSortField="mdbRaw"
                                       columns={props.columns}
                                       data={props.coils}
                            />
                        </div>
                    </MediaQuery>
                    <MediaQuery maxDeviceWidth={768}>
                        <DataTable
                            title={"Inventory"}
                            highlightOnHover={false}
                            pointerOnHover={false}
                            defaultSortField="name"
                            columns={props.mobileColumns}
                            data={props.coils}
                            expandableRows={true}
                            expandableRowsComponent={<ExpanableRow data="" manualBalance={props.manualBalance}/>}
                        />
                    </MediaQuery>
                </div>
                : <div className={"box-body content"}>
                    <DataTable key={5}
                               title={"Claw Machine Stock"}
                               highlightOnHover={false}
                               pointerOnHover={false}
                               defaultSortField="stock"
                               columns={props.clawMachineStockColumns}
                               data={props.stocks}
                    />
                    <DataTable key={6}
                               title={"Recent Dispensed Records"}
                               highlightOnHover={false}
                               pointerOnHover={false}
                               defaultSortField="dispensedTime"
                               columns={props.clawMachineDispenseRecordsColumns}
                               data={props.clawMachineDispenseRecords}
                    />
                </div>
            }
        </div>
    </div>
}