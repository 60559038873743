import React, {Component} from "react";
import {VendingMachine} from "./models";
import GoogleMapReact from "google-map-react";
import {VendingMachineMarker} from "./vending_machine_marker";

// @ts-ignore
let google = window.google;

interface Props {
    vendingMachines: Array<VendingMachine>
    zoom?: number
}

interface State {
    zoom: number
}

export class BirdView extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            zoom : props.zoom || 10
        }
    }

    render() {
        if (!google) {
            return (<h1>Google map not available</h1>);
        }
        const bounds = new google.maps.LatLngBounds();

        let markers: any = [];

        let vms;

        if (this.props.vendingMachines.length > 60){
            vms = this.props.vendingMachines.filter((vm)=>{return vm.online})
        } else {
            vms = this.props.vendingMachines
        }
        vms.forEach((vendingMachine) => {
            if (vendingMachine.geo_location && vendingMachine.geo_location.latitude && vendingMachine.geo_location.longitude) {
                let latlng: google.maps.LatLngLiteral = {
                    lat: vendingMachine.geo_location.latitude,
                    lng: vendingMachine.geo_location.longitude
                };

                console.log("Extend: ", latlng)
                bounds.extend(latlng);

                markers.push(
                    (<VendingMachineMarker
                        key={vendingMachine.uuid}
                        lat={vendingMachine.geo_location.latitude}
                        lng={vendingMachine.geo_location.longitude}
                        vendingMachine={vendingMachine}
                    />)
                );
            }
        });

        if (!bounds.getCenter()) {
            console.error("Error: bounds center is null.");
            return (<div></div>);
        }

        const center = {
            lat: bounds.getCenter().lat(),
            lng: bounds.getCenter().lng()
        };
        console.log('center');
        console.log(center);
        return (
            <div style={{height: '300px', width: '100%'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: "AIzaSyAiK6esuGcx32taWwFdMdQBGlNF0eNQkhs"}}
                    center={center}
                    zoom={10}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({ map, maps }) => {
                        map.fitBounds(bounds);
                        map.setZoom(this.state.zoom); 
                    }}
                >
                    {markers}
                </GoogleMapReact>
            </div>
        );
    }
}
