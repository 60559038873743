import React, {useCallback, useEffect, useState} from "react";
import {
    CoilProductStats,
    CompanyUserRole,
    IngredientMapping,
    IngredientMappingWithProduct,
    ProductMapping,
    ProductMappingWithProduct,
    VendingMachine
} from "./models";
import CoilsStatsDataTableCell from './components/CoilsStatsDataTableCell';
import {Link} from "react-router-dom";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {EditableProductName} from './components/editable_product_name';
import {DisplayTime} from "./display_time";
import ShelftimeDisplay from "./shelftime_display";
import InlineProductPhoto from "./inline_product_photo";
import DateDisplay from "./date_display";
import {DriverCoilStatsTable} from "./driver_coil_stats.table";
import {AdminCoilStatsTable} from "./admin_coil_stats_table";
import {FormatMoneyWithFlexibleSignSize} from "./format_money_without_style";
import IngredientMapsStatsDataTableCell from "./components/IngredientMapsStatsDataTableCell";
import {V1ClawMachineDispenseRecord, V1ClawMachineStock} from "./telemetry/vending_machine/v1/typescript-axios";
import { ManagementServiceApi } from "./api";
import moment from "moment/moment";

interface IProps extends IGlobalDataContext {
    vendingMachine: VendingMachine
    coils: Array<ProductMapping>

    manualBalance(coil: ProductMapping, newStock: number)
    ingredientMappingManualBalance(ingredientMapping: IngredientMapping, newStock: number)

    reloadProductMappings()

    ingredientMappings: Array<IngredientMapping>
}

const _CoilsStatsDataTable: React.FunctionComponent<IProps> = props => {
    let { coils, manualBalance, ingredientMappingManualBalance, vendingMachine, reloadProductMappings, ingredientMappings } = props;
    let [stocks, setStocks] = useState<Array<V1ClawMachineStock>>([]);

    const[clawMachineDispenseRecords,setClawMachineDispenseRecords] = useState<Array<V1ClawMachineDispenseRecord>>([]);

    const [aggregatedByProduct, setAggregatedByProduct] = useState(false);

    const getClawMachineStocks = useCallback(() => {
        console.log('getClawMachineStocks');
        new ManagementServiceApi()
            .machineManagementServiceGetClawMachineStocks(props.vendingMachine.uuid)
            .then(res => {
                setStocks(res.data.stocks ?? []);
            });
    }, [props.vendingMachine.uuid]);

    const getClawMachineLatestDispenseRecords = useCallback(() =>{
        new ManagementServiceApi()
            .machineManagementServiceListClawMachineDispenseRecords(props.vendingMachine.uuid,0,20)
            .then(res => {
                setClawMachineDispenseRecords(res.data.dispenseRecords ?? []);
            });
    },[props.vendingMachine.uuid]);

    useEffect(() => {
        if (props.vendingMachine.vendingMachineType === 'CLAW_MACHINE') {
            getClawMachineStocks();
            getClawMachineLatestDispenseRecords()
        }
    }, [props.vendingMachine.vendingMachineType, getClawMachineStocks,getClawMachineLatestDispenseRecords]);


    const coilsWithProduct = coils.map((coil)=>{
        const p = props.findProduct(coil.product_id);
        const coilWithProduct:ProductMappingWithProduct = {...coil, product : p};
        return coilWithProduct;
    })

    const ingredientMappingsWithProduct = ingredientMappings.map((ingredientMapping)=>{
        const p = props.findProduct(String(ingredientMapping.ingredientId))

        const ingredientMappingWithProduct:IngredientMappingWithProduct = {...ingredientMapping, product : p};
        return ingredientMappingWithProduct;
    })

    const mobileColumns = [
        {
            minWidth: '0px',
            maxWidth: '60px',
            compact: true,
            name: "Coil",
            selector: "mdbRaw",
            sortable: true,
            cell: row => <div>{row.code}
                <small>[{row.mdb_code}]</small>
            </div>,
        },
        {
            minWidth: '0px',
            maxWidth: '100px',
            name: "Product",
            selector: "product_name",
            sortable: true
        },
        {
            compact: true,
            name: "Price",
            minWidth: '0px',
            maxWidth: '40px',
            selector: "price",
            format: row => <FormatMoneyWithFlexibleSignSize cents={row.price}/>,
            sortable: true
        },
        {
            name: "Current Stock",
            minWidth: '0px',
            maxWidth: '100px',
            selector: (row: ProductMapping) => {
                return row.restock - (row.currentStock || 0);
            },
            cell: row => {
                return (
                    <CoilsStatsDataTableCell manualBalance={manualBalance}
                                             coil={row} currentStock={row.currentStock} restock={row.restock}/>
                )
            },
            sortable: true
        },
    ];

    const clawMachineStockColumns = [
        {
            name: "Coil",
            selector: "mdbItemNumber",
        }
        ,
        {
            name: "Product",
            selector: "productName",
        },
        {
            name: "Price",
            selector: "price",
            format: row => <FormatMoneyWithFlexibleSignSize cents={row.price} />,
        },
        {
            name: "Current Stock",
            selector: "currentStock",
        },
    ];

    const clawMachineDispenseRecordColumns = [
        {
            name: 'Index',
            selector: (row, index) => index + 1,
            sortable: false,
        },
        {
            name: "DispensedTime(UTC)",
            selector: "dispensedTime",
        },
        {
            name: "DispensedTime",
            selector: "dispensedTime",
            format: ((row: V1ClawMachineDispenseRecord) => {
                if (row.dispensedTime) {
                    return (<span><DisplayTime timestamp={moment(row.dispensedTime).unix()}/></span>);
                }
                return null
            })
        }
    ];

    const ingredientColumns = [
        {
            name: "Ingredient",
            selector: "ingredientName",
            sortable: true,
            ignoreRowClick: true,
            allowOverflow: true,
            cell: (row) => {

                if (row.product == null){
                    return "loading...";
                }

                if (!row.product.placeholder){
                    return <div>
                        <InlineProductPhoto product={row.product}/>

                        {row.product.name}
                    </div>;
                }

                return (<EditableProductName reloadProductMappings={reloadProductMappings} product={row.product}/>);
            }
        },
        {
            name: "Current Stock",
            selector: (row: IngredientMapping) => {
                return row.restockCapacity - (row.currentLevel || 0);
            },
            ignoreRowClick: true,
            cell: row => {
                return (
                    <div>
                        <IngredientMapsStatsDataTableCell manualBalance={ingredientMappingManualBalance}
                                                 ingredientMapping={row} currentStock={row.currentLevel} restock={row.restockCapacity}/>
                    </div>
                )
            },
            sortable: true
        },
        {
            name: "Last transaction time",
            sortable: true,
            selector: "lastTransactionTimestamp",
            format: ((row: IngredientMapping) => {
                if (row.lastTransactionTimestamp) {
                    return (<span><DisplayTime timestamp={row.lastTransactionTimestamp}/></span>);
                }

                return null;
            })
        },
        {
            name: "Longest shelftime/Expiry Date",
            ignoreRowClick: true,
            sortable: true,
            selector: row => {
                if (row.coilShelfTimes?.length > 0) {
                    return row.coilShelfTimes[0].timestamp;
                }
                return 0;
            },
            cell: row => {
                if (row.coilShelfTimes?.length > 0){
                    if (row.coilShelfTimes[0].expiryDate != null){
                        return <p> <DateDisplay date={row.coilShelfTimes[0].expiryDate}/></p>
                    }
                }
                return <ShelftimeDisplay coil={row}/>
            }
        }
    ]

    const columns = [
        {
            compact: true,
            name: "Coil",
            selector: "mdbRaw",
            sortable: true,
            ignoreRowClick:true,
            cell: (row: ProductMapping) => {
                if (row.warnLastTransactionFlag) {
                    return (
                        <div><Link to={"/vending_machines/"+vendingMachine.uuid+"/sales/?mdbs="+row.mdbRaw}>
                            <span>{row.code}</span>
                            </Link>
                            <small style={{display:'inline-block'}}>[{row.mdb_code}]</small>
                            &nbsp;&nbsp;&nbsp;
                            <p className={"text-danger"}>No sales for the last 20 days</p>
                        </div>
                    );
                } else {
                    return (
                        <div><Link to={"/vending_machines/"+vendingMachine.uuid+"/sales/?mdbs="+row.mdbRaw}>
                            <span>{row.code}</span>
                        </Link>
                            <small>[{row.mdb_code}]</small>
                        </div>
                    );
                }
            }
        },
        {
            name: "Product",
            selector: "product_name",
            sortable: true,
            ignoreRowClick: true,
            allowOverflow: true,
            cell: (row) => {
                if (row.product == null){
                    return "loading...";
                }

                if (!row.product.placeholder){
                    return <div>
                        <InlineProductPhoto product={row.product}/>

                        {row.product.name}
                    </div>;
                }

                return (<EditableProductName reloadProductMappings={reloadProductMappings} product={row.product}/>);
            }
        },
        {
            compact: true,
            name: "Price",
            selector: "price",
            format: row => <FormatMoneyWithFlexibleSignSize cents={row.price}/>,
            sortable: true
        },
        {
            name: "Current Stock",
            selector: (row: ProductMapping) => {
                return row.restock - (row.currentStock || 0);
            },
            ignoreRowClick: true,
            cell: row => {
                return (
                    <CoilsStatsDataTableCell manualBalance={manualBalance}
                                             coil={row} currentStock={row.currentStock} restock={row.restock}/>
                )
            },
            sortable: true
        },
        {
            name: "Last transaction time",
            sortable: true,
            selector: "lastTransactionTimestamp",
            format: ((ProductMapping: ProductMapping) => {
                if (ProductMapping.lastTransactionTimestamp) {
                    return (<span><DisplayTime timestamp={ProductMapping.lastTransactionTimestamp}/></span>);
                }

                return null;
            })
        },
        {
            name: "Longest shelftime/Expiry Date",
            ignoreRowClick: true,
            sortable: true,
            selector: row => {
                if (row.coilShelfTimes?.length > 0) {
                    return row.coilShelfTimes[0].timestamp;
                }
                return 0;
            },
            cell: row => {
                if (row.coilShelfTimes?.length > 0){
                    if (row.coilShelfTimes[0].expiryDate != null){
                        return <p> <DateDisplay date={row.coilShelfTimes[0].expiryDate}/></p>
                    }
                }
                return <ShelftimeDisplay coil={row}/>
            }
        },
    ];

    let aggregatedProducts: Record<number, CoilProductStats> = {};

    coils.forEach((coil: ProductMapping) => {
        const productId = coil.product_id;
        const productName = coil.product_name;
        const remaining = coil.currentStock;
        const capacity = coil.capacity;
        const restock = coil.restock;

        if (productId in aggregatedProducts) {
            const old = aggregatedProducts[productId];
            console.log(old);
            aggregatedProducts[productId] = {
                stock: remaining + old.stock,
                restock: restock + old.restock,
                capacity: capacity + old.capacity,
                productName: old.productName,
            };
        } else {
            aggregatedProducts[productId] = {
                stock: remaining,
                capacity: capacity,
                restock: restock,
                productName: productName,
            }
        }
    });

    const products = Object.entries(aggregatedProducts).map((entry) => {
        return entry[1];
    });
    console.log(products);

    let aggregatedColumns = [
        {
            name: "Product",
            selector: "productName",
            sortable: true,
        },
        {
            name: "Stock (stock/restock/capacity)",
            sortable: true,
            selector: (product: CoilProductStats) => {
                return product.restock - product.stock
            },
            cell: (productStats: CoilProductStats) => {
                return (
                    <span>
                        <small>{productStats.stock} / {productStats.restock} / {productStats.capacity}</small>
                        <br/>
                        Short: <b>{productStats.restock - productStats.stock}</b> - {((productStats.restock - productStats.stock) * 100.0 / productStats.restock).toFixed(2)}%
                    </span>
                )
            }
        },
    ];

    if (props.me.role === CompanyUserRole.DRIVER.valueOf()) {
        return <DriverCoilStatsTable vendingMachine={vendingMachine}
                                     setAggregatedByProduct={setAggregatedByProduct}
                                     aggregatedByProduct={aggregatedByProduct}
                                     aggregatedColumns={aggregatedColumns}
                                     products={products}
                                     columns={columns}
                                     coils={coilsWithProduct}
                                     stocks={stocks}
                                     clawMachineStockColumns={clawMachineStockColumns}
                                     mobileColumns={mobileColumns}
                                     manualBalance={manualBalance}
                                     clawMachineDispenseRecords={clawMachineDispenseRecords}
                                     clawMachineDispenseRecordsColumns={clawMachineDispenseRecordColumns}
                                     />
    }
    return <AdminCoilStatsTable vendingMachine={vendingMachine}
                                setAggregatedByProduct={setAggregatedByProduct}
                                aggregatedByProduct={aggregatedByProduct}
                                aggregatedColumns={aggregatedColumns}
                                products={products}
                                columns={columns}
                                coils={coilsWithProduct}
                                stocks={stocks}
                                mobileColumns={mobileColumns}
                                clawMachineStockColumns={clawMachineStockColumns}
                                manualBalance={manualBalance}
                                ingredientMappingManualBalance={ingredientMappingManualBalance}
                                ingredientColumns={ingredientColumns}
                                ingredientMappings={ingredientMappingsWithProduct}
                                clawMachineDispenseRecords={clawMachineDispenseRecords}
                                clawMachineDispenseRecordsColumns={clawMachineDispenseRecordColumns}
                                />
};


const CoilsStatsDataTable = withGlobalData(_CoilsStatsDataTable);
export default CoilsStatsDataTable;

