import {Link, Route, Switch} from "react-router-dom";
import VendingMachinePlanogram from "./vending_machine_planogram";
import CoilsStatsDataTable from "./coils_stats_data_table";
import {TransactionsComponent} from "./transactions_component";
import {VirtualFinancialTransactions} from "./virtual_financial_transactions";
import {RefillsComponent} from "./refills_component";
import RemoteVend from "./remote_vend";
import NotificationTab from "./Notification_tab"
import React, {useEffect, useState} from "react";

import {
    DailyRevenue,
    IngredientMapping,
    ProductMapping,
    Refill,
    Tube,
    VendingMachine,
    VendingMachineFinancialStats
} from "./models";
import {DisplayTime} from "./display_time";
import {NavTab, RoutedTabs} from 'react-router-tabs';
import {FormatMoney} from "./format_money";
import {DailyRevenuesBox} from "./daily_revenues_box";
import {Tube as TubeComponent} from "./tube";
import {FormatMoneyWithFlexibleSignSize} from "./format_money_without_style";
import {VendingOnTrackInside} from "./voti_operation_tab";
import {MachineSignalInfo} from "./machine_signal_info";
import {V1MachineDetailResponse} from "./telemetry/vending_machine/v1/typescript-axios";
// import {BirdView} from "./bird_view";
import {ManagementServiceApi} from "./api";


interface Props {
    vending_machine_name: string | null
    match: any
    vending_machine: VendingMachine | null
    serviceUrl: string
    online: boolean | null

    setManualBalance(coil: ProductMapping, newStockLevel: number)

    setIngredientMappingManualBalance(ingredientMapping: IngredientMapping, newStockLevel: number)

    reloadCoils()

    coils: Array<ProductMapping>
    ingredientMappings: Array<IngredientMapping>

    loadVendingMachineDetail()

    virtual_financial_transactions

    virtualPagin()

    dex_error: string | null,
    last_dex_reading: string | JSX.Element
    virtual_financial_transactions_total_page
    refills: Array<Refill>
    stats: VendingMachineFinancialStats | null
    isMobile: boolean
    refillTime: string
    note_amount: number,
    note_count: number,
    dailyRevenueLoading: boolean,
    reportDuration: number,

    handleDurationChange(e: any)

    dailyRevenues: Array<DailyRevenue>
    height: number
    tubeTimestamp: number | null
    tubes: Array<Tube>
    currencySymbol: string
}


export function AdminVendingMachineStats(props: Props) {
    const [machineDetail, setMachineDetail] = useState<V1MachineDetailResponse | null>(null)
    // const [location, setLocation] = useState<GeoLocation | null>(null);

    useEffect(() => {
        if (props.vending_machine?.uuid) {
            loadMachineConfiguration();
        }

        // getMachineLatestLocation();

        // eslint-disable-next-line
    }, [props.vending_machine?.uuid])


    const loadMachineConfiguration = () => {
        new ManagementServiceApi().machineManagementServiceMachineDetail(props.vending_machine?.uuid as string).then(
            res => {
                setMachineDetail(res.data)
            }).catch((e) => {
            alert("get machine detail error " + e)
        });
    }

    // const getMachineLatestLocation = () => {
    //     if (props.vending_machine?.uuid != null) {
    //         new ManagementServiceApi()
    //             .machineManagementServiceGetMachineLatestLocation(
    //                 props.vending_machine?.uuid
    //             )
    //             .then((res) => {
    //                 const loc = res.data.location;
    //                 if (loc && loc.latitude && loc.longitude) {
    //                     setLocation({
    //                         latitude: loc.latitude,
    //                         longitude: loc.longitude,
    //                         address: ''
    //                     });
    //                 }
    //             })
    //             .catch((e) => {
    //                 alert("get machine detail error " + e);
    //             });
    //     }
    // }

    return (<section className="content-header">
            <h1>
                Vending Machine Stats
                [
                <span>{props.vending_machine_name}</span>
                ]
                <small>
                    <Link to={`/vending_machines/${props.match.params.uuid}/setting`}>Settings</Link>
                </small>
            </h1>

            {
                props.vending_machine && props.vending_machine.archived ?
                    <div className="alert alert-danger"><b>Note: </b> This vending machine is archived.</div> : null
            }

            {/* {
                props.vending_machine && (props.vending_machine.installedTelemetryDeviceUuid == null) ?
                    <div>
                        <br/>
                        <div className="alert alert-warning">
                            <b>Warning: </b> this vending machine does not have telemetry device attached.
                        </div>
                    </div>

                    :
                    null
            } */}

            {
                props.serviceUrl &&
                <Link to={props.serviceUrl} className="btn btn-info">Service</Link>
            }

            {
                props.online != null ?
                    (
                        <p className={(props.online ? "text-success" : "text-warning") + " text"}>
                            <i className={"fa fa-circle"}>&nbsp;</i>
                            {props.online ? "Online" : "Offline"}
                        </p>
                    )
                    :
                    null
            }

            {
                (props.vending_machine?.creditCardReaderOnTestEnv ?? false) && <div style={{color: "red"}}>
                    TESTING MODE
                </div>
            }

            <RoutedTabs startPathWith={`/vending_machines/${props.match.params.uuid}`}>
                <NavTab exact to={`/`}>
                      <span className="hidden-xs">
                        Summary &nbsp;
                      </span>
                    <i className="fa  fa-bar-chart"></i>
                </NavTab>
                <NavTab exact to={`/planogram`}>
                      <span className="hidden-xs">
                        Planogram &nbsp;
                      </span>
                    <i className="fab fa-product-hunt"></i>
                </NavTab>
                <NavTab exact to={`/coils`}>
                      <span className="hidden-xs">
                        Stock &nbsp;
                      </span>
                    <i className="fa fa-layer-group"></i>
                </NavTab>
                <NavTab exact to={`/sales`}>
                      <span className="hidden-xs">
                        Sales &nbsp;
                      </span>
                    <i className="fa fa-line-chart"></i>
                </NavTab>
                <NavTab exact to={`/cash`}>
                      <span className="hidden-xs">
                        Financial &nbsp;
                      </span>
                    <i className="fa fa-dollar"></i>
                </NavTab>
                <NavTab exact to={`/refills`}>
                      <span className="hidden-xs">
                        Refills &nbsp;
                      </span>
                    <i className="fa fa-truck"></i>
                </NavTab>
                <NavTab exact to={`/remote_vend`}>
                        <span className="hidden-xs">
                            Remote Vend &nbsp;
                        </span>
                    <i className="fa fa-user"></i>
                </NavTab>
                <NavTab exact to={`/notification`}>
                        <span className="hidden-xs">
                            Notification &nbsp;
                        </span>
                    {(props.vending_machine && props.vending_machine.activeNotificationCount > 0) ?
                        <span className="badge bg-yellow">
                            {props.vending_machine.activeNotificationCount}
                        </span>
                        :
                        <span className="badge">
                            {0}
                        </span>
                    }
                </NavTab>
                <NavTab exact to={`/voti`}>
                        <span className="hidden-xs">
                            VoTi &nbsp;
                        </span>
                    <i title={"Vending Machine Features with Vending on Track Inside"} className="fa fa-star"></i>
                </NavTab>

            </RoutedTabs>

            <Switch>
                <Route exact path={`/vending_machines/${props.match.params.uuid}/planogram`}>
                    {props.vending_machine &&
                        <VendingMachinePlanogram vendingMachine={props.vending_machine}/>
                    }
                </Route>
                <Route exact path={`/vending_machines/${props.match.params.uuid}/coils`}>
                    {props.vending_machine &&
                        <CoilsStatsDataTable vendingMachine={props.vending_machine}
                                             manualBalance={props.setManualBalance}
                                             ingredientMappingManualBalance={props.setIngredientMappingManualBalance}
                                             reloadProductMappings={props.reloadCoils}
                                             coils={props.coils}
                                             ingredientMappings={props.ingredientMappings}/>
                    }
                </Route>

                <Route exact path={`/vending_machines/:uuid/sales`}>
                    <TransactionsComponent
                        vendingMachine={props.vending_machine}
                    />
                </Route>

                <Route exact path={`/vending_machines/${props.match.params.uuid}/cash`}>
                    <VirtualFinancialTransactions
                        vendingMachine={props.vending_machine}
                        transactions={props.virtual_financial_transactions}
                        handlePagin={props.virtualPagin}
                        totalpage={props.virtual_financial_transactions_total_page}
                        refills={props.refills}/>
                </Route>

                <Route exact path={`/vending_machines/${props.match.params.uuid}/refills`}>
                    {props.refills ?
                        <RefillsComponent refills={props.refills}
                                          refillsShouldReload={props.loadVendingMachineDetail}/>
                        :
                        null
                    }
                </Route>

                <Route exact path={`/vending_machines/${props.match.params.uuid}/remote_vend`}>
                    <RemoteVend vendingMachine={props.vending_machine} coils={props.coils}/>
                </Route>

                {props.vending_machine &&
                    <Route exact path={`/vending_machines/${props.match.params.uuid}/voti`}>
                        <VendingOnTrackInside vendingMachine={props.vending_machine}/>
                    </Route>
                }

                <Route exact path={`/vending_machines/${props.match.params.uuid}/notification`}>
                    <NotificationTab uuid={props.match.params.uuid}
                                     loadVendingMachineDetail={props.loadVendingMachineDetail}/>
                </Route>

                <Route exact path={`/vending_machines/${props.match.params.uuid}`}>
                    <div className={"box"}>
                        <div className={"box-header"}>
                            <h1>
                                Financial Status
                                {props.isMobile && <br/>}
                                {props.stats ?
                                    (<small>&nbsp; Updated <DisplayTime timestamp={props.stats.timestamp}/>
                                    </small>)
                                    : null}
                            </h1>
                        </div>

                        {!props.stats ? null :
                            (
                                <div className={"box-body"}>
                                    <section className="content">
                                        <div className="row">
                                            <div className="col-xl-2 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="info-box">
                                                    <span className="info-box-icon bg-green"><i
                                                        className="fa fa-ticket"> </i></span>

                                                    <div className="info-box-content">
                                                        <span className="info-box-text">Today's Sales</span>
                                                        <span
                                                            className="info-box-number">{props.stats.salesCount}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-2 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="info-box">
                                                        <span className="info-box-icon bg-aqua">
                                                            <i className="fa fa-shopping-cart"> </i>
                                                        </span>

                                                    <div className="info-box-content">
                                                        <span className="info-box-text">Today's Revenue</span>
                                                        <span
                                                            className="info-box-number"><FormatMoneyWithFlexibleSignSize
                                                            cents={props.stats.today_revenue}/>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-xl-2 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="info-box">
                                                    <span className="info-box-icon bg-green"><i
                                                        className="fa fa-ticket"> </i></span>

                                                    <div className="info-box-content">
                                                            <span className="info-box-text">
                                                                Since Refill
                                                                <i style={{cursor: "pointer"}}
                                                                   className="fa fa-info-circle"
                                                                   title={props.refillTime}>
                                                                    &nbsp;
                                                                </i>
                                                            </span>
                                                        <span className="info-box-number">
                                                                {props.stats.salesSinceRefill}
                                                            &nbsp;
                                                            <small>sales</small>
                                                                <br/>
                                                                <FormatMoney
                                                                    cents={props.stats.revenueSinceRefill}/>
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-2 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="info-box">
                                                        <span className="info-box-icon bg-aqua">
                                                            <i className="fa fa-shopping-cart"> </i>
                                                        </span>

                                                    <div className="info-box-content">
                                                        <span className="info-box-text">Week to date</span>
                                                        <span className="info-box-number">
                                                                 {props.stats.weekSales}
                                                            &nbsp;
                                                            <small>sales</small>
                                                                <br/>
                                                                <FormatMoney cents={props.stats.weekRevenue}/>
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-xl-2 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="info-box">
                                                        <span className="info-box-icon bg-red">
                                                            <i className="fa fa-sticky-note"> </i>
                                                        </span>

                                                    <div className="info-box-content">
                                                        <span className="info-box-text">Bill / Note</span>
                                                        <span className="info-box-number">
                                                                <FormatMoneyWithFlexibleSignSize
                                                                    cents={props.note_amount}/>
                                                            <br/>
                                                            {props.note_count} notes
                                                            </span>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-xl-2 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="info-box">
                                                        <span className="info-box-icon bg-red">
                                                            <i className="fa fa-dollar"> </i>
                                                        </span>

                                                    <div className="info-box-content">
                                                        <span className="info-box-text">Coin Box</span>
                                                        <span className="info-box-number">
                                                            <FormatMoneyWithFlexibleSignSize
                                                                cents={props.stats.coin_box_amount}/>
                                                            <br/>
                                                            {props.stats.coin_box_count} coins
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="clearfix visible-sm-block"></div>


                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <DailyRevenuesBox dailyRevenueLoading={props.dailyRevenueLoading}
                                                                  reportDuration={props.reportDuration}
                                                                  handleDurationChange={props.handleDurationChange}
                                                                  dailyRevenues={props.dailyRevenues}
                                                                  currencySymbol={props.currencySymbol}
                                                                  height={props.height}/>
                                                <MachineSignalInfo machineDetail={machineDetail}/>

                                                {/*{props.vending_machine && props.vending_machine.vendingMachineType === 'CLAW_MACHINE' &&*/}
                                                {/*    <div className="box box-success well">*/}
                                                {/*        <div className="box-header with-border">*/}
                                                {/*            <h3 className="box-title">Claw Machine Location</h3>*/}
                                                {/*        </div>*/}
                                                {/*        <div className="box-body">*/}
                                                {/*            <div style={{height: '100', width: '100%'}}>*/}
                                                {/*                <BirdView*/}
                                                {/*                    zoom={15}*/}
                                                {/*                    vendingMachines={[*/}
                                                {/*                        {*/}
                                                {/*                            ...props.vending_machine,*/}
                                                {/*                            geo_location: location,*/}
                                                {/*                        },*/}
                                                {/*                    ]}/>*/}
                                                {/*            </div>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*}*/}
                                            </div>

                                            <div className="col-md-6">
                                                <div className="box box-success well">
                                                    <div className="box-header">
                                                        <h3>
                                                            Tube Status Timestamp: &nbsp;
                                                            {props.tubeTimestamp ?
                                                                <DisplayTime
                                                                    timestamp={(props.tubeTimestamp || 0)}/>
                                                                :
                                                                null}
                                                        </h3>
                                                    </div>

                                                    <div className="box-body">
                                                        {props.tubes.map((t) => {
                                                            return (
                                                                <div key={t.value} className="tubes row">
                                                                    <div className="col-xs-12 col-md-12">
                                                                        <TubeComponent tube={t}/>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            )
                        }
                    </div>
                </Route>
            </Switch>
        </section>
    )
        ;
}
